<template>
    <div>
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>流量组</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/customerCard' }">
                    咨询信息
                </el-breadcrumb-item>
                <el-breadcrumb-item>编辑</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="detail-info">
            <el-form ref="form" v-loading="formLoading" :model="form" :rules="rules" label-width="140px" size="mini">
                <el-form-item class="input" label="咨询类型: " label-position="left" prop="type">
                    <el-select v-model="form.type" class="search-select" clearable placeholder="状态" size="mini">
                        <el-option :value="1" label="书本"> </el-option>
                        <el-option :value="2" label="课程"> </el-option>
                        <el-option :value="3" label="资料"> </el-option>
                        <el-option :value="4" label="其他"> </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="备注: " label-position="left" prop="remark">
                    <el-input v-model="form.remark" style="width: 500px" type="textarea"></el-input>
                </el-form-item>

                <el-form-item label="图片: " label-position="left" prop="pics">
                    <paste-image :picArray="form.pics" upload-path="sales" @onDelete="onDelete"
                        @onSuccess="onSuccess"></paste-image>
                </el-form-item>
                <el-form-item>
                    <el-button :loading="loading" size="small" type="primary" @click="onSubmit">
                        保存
                    </el-button>
                    <el-button size="small" style="margin-left: 10px" @click="$router.back()">
                        取消
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import PasteImage from "@/components/pasteImage/index";
export default {
    name: "Edit",
    components: {
        PasteImage
    },
    data() {
        return {
            loading: false,
            form: {
                pics: [],
            },
            formLoading: false,
            rules: {
                type: [
                    { required: true, trigger: "change", message: "请选择类型" },
                ],
                remark: [
                    { required: true, trigger: "blur", message: "请输入备注" },
                ],
                pics: [
                    { required: true, trigger: "change", message: "请选择图片" },
                ],
            },
        };
    },
    methods: {
        ...mapActions("customerCard", ["addCustomerConsult", "getCustomerConsult"]),
        onSuccess(pic) {
            this.form.pics = pic
        },
        onDelete(pic) {
            this.form.pics = pic
        },
        onSubmit() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.addClass();
                }
            });
        },
        async addClass() {
            this.loading = true;
            const { res_info } = await this.addCustomerConsult(this.form);
            this.loading = false;
            this.$router.back();
            if (res_info != "ok") return;
            this.$message.success("创建成功");
        },
        async init(id) {
            this.formLoading = true;
            const { data } = await this.getCustomerConsult({ id })
            console.log(data);
            this.form = data
            this.formLoading = false
        },
    },
    mounted() {
      
        this.init(this.$route.params.customerConsultId)
    },
};
</script>
